import {objArrMapObj} from "@/untils/otherEvent"

/** 列表数据 */
export const APP_VERSION_LINE_TABLE_LIST = [
    { title: "App平台", align: "center", scopedSlots: { customRender: 'itemAppPlatformSlot'} },
    { title: "版本号", align: "center", dataIndex: 'appVersion' },
    { title: "App更新内容", align: "center", scopedSlots: { customRender: 'itemAppUpdateContentSlot'}, width: 300 },
    { title: "更新类型", align: "center", scopedSlots: { customRender: 'itemAppUpdateSlot'} },
    { title: "下载路径", align: "center", scopedSlots: { customRender: 'itemAppDownloadUrlSlot'} },
    { title: "操作", align: "center", scopedSlots: { customRender: 'itemActionSlot'} },
]

/** app平台 */
export const APP_PLATFORM_TYPE_LIST = [
    { name: '安卓', value: 1 },
    { name: 'IOS', value: 2 },
]

/** app平台对应类类型 */
export const APP_PLATFORM_CLASS_TYPE = new Map([
    [1, 'color-green'],
    [2, 'color-blue']
])

/** app更新类型 */
export const APP_UPDATE_TYPE_LIST = [
    { name: '不更新', value: 0 },
    { name: '提示更新', value: 1 },
    { name: '强制更新', value: 2 },
]

/** app更新类型类 */
export const APP_UPDATE_CLASS_TYPE = {
    0: 'color-gray',
    1: 'color-orange',
    2: 'color-red'
}

export const appUpdateTypeMapText = (value) => {
    return objArrMapObj('value', 'name', APP_UPDATE_TYPE_LIST)[value]
}

export const appPlatformTypeMapText = (value) => {
    return objArrMapObj('value', 'name', APP_PLATFORM_TYPE_LIST)[value]
}