var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: { width: 800, title: _vm.titleText },
          on: { ok: _vm.handleConfirm, cancel: _vm.handleCancel },
          model: {
            value: _vm.showPopup,
            callback: function($$v) {
              _vm.showPopup = $$v
            },
            expression: "showPopup"
          }
        },
        [
          _c(
            "a-form-model",
            _vm._b(
              { ref: "ruleForm", attrs: { model: _vm.params } },
              "a-form-model",
              _vm.layout,
              false
            ),
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "版本号" } },
                [
                  _c("a-input", {
                    staticClass: "w-200",
                    attrs: { placeholder: "版本号" },
                    model: {
                      value: _vm.params.appVersion,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "appVersion", $$v)
                      },
                      expression: "params.appVersion"
                    }
                  })
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "app平台" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-5 w-150",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "app平台类型"
                      },
                      model: {
                        value: _vm.params.deviceType,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "deviceType", $$v)
                        },
                        expression: "params.deviceType"
                      }
                    },
                    _vm._l(_vm.appPlatformTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "更新类型" } },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "ml-5 w-150",
                      attrs: {
                        allowClear: "",
                        showSearch: "",
                        placeholder: "app更新状态"
                      },
                      model: {
                        value: _vm.params.needUpdated,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "needUpdated", $$v)
                        },
                        expression: "params.needUpdated"
                      }
                    },
                    _vm._l(_vm.appUpdateTypeList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.value, attrs: { value: item.value } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "更新描述" } },
                [
                  _c("a-input", {
                    staticClass: "w-100-w",
                    attrs: {
                      type: "textarea",
                      "auto-size": { minRows: 6, maxRows: 10 },
                      placeholder: "更新内容描述"
                    },
                    model: {
                      value: _vm.params.content,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "content", $$v)
                      },
                      expression: "params.content"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }