<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.appVersion"
          class="w-200"
          placeholder='请输入版本号'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="ml-5 w-150"
          v-model="params.deviceType"
          placeholder="app平台类型"
      >
        <a-select-option
            v-for="item of appPlatformTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="ml-5 w-150"
          v-model="params.needUpdated"
          placeholder="app更新状态"
      >
        <a-select-option
            v-for="item of appUpdateTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          class="ml-5"
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          class="ml-5"
          @click="handleAddVersion"
      >新增版本</a-button>
    </div>
    <div class="table-content">
      <TableList
        :list="data"
        :pagination="pagination"
        :loading="loading"
        @changePage="handleChangePage"
        @success="getList"
      />
    </div>
<!--  新增  -->
    <VersionEditorPopup ref="versionEditorPopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/appVersionManage/_components/TableList"
import {getAppVersionList} from "@/views/cmsPage/appVersionManage/_api"
import {APP_PLATFORM_TYPE_LIST, APP_UPDATE_TYPE_LIST} from "@/views/cmsPage/appVersionManage/_data"
import VersionEditorPopup from "@/views/cmsPage/appVersionManage/_components/VersionEditorPopup"

export default {
  components: {
    TableList,
    VersionEditorPopup,
  },
  data() {
    return {
      data: [],
      appPlatformTypeList: APP_PLATFORM_TYPE_LIST,
      appUpdateTypeList: APP_UPDATE_TYPE_LIST,
      loading: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 20,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        deviceType: undefined,
        appVersion: undefined,
        needUpdated: undefined,
      },
    };
  },
  watch: {
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 新增版本 */
    handleAddVersion() {
      this.$refs.versionEditorPopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getAppVersionList(this.params)
      this.$loading.hide()
      if (res.status != 200) return
      this.data = res.data.records
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}
</style>
