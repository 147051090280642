<template>
  <div>
    <a-modal
        :width="800"
        v-model="showPopup"
        :title="titleText"
        @ok="handleConfirm"
        @cancel="handleCancel"
    >
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-model-item label="版本号">
          <a-input
              class="w-200"
              v-model="params.appVersion"
              placeholder="版本号"
          />
        </a-form-model-item>
        <a-form-model-item label="app平台">
          <a-select
              allowClear
              showSearch
              class="ml-5 w-150"
              v-model="params.deviceType"
              placeholder="app平台类型"
          >
            <a-select-option
                v-for="item of appPlatformTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="更新类型">
          <a-select
              allowClear
              showSearch
              class="ml-5 w-150"
              v-model="params.needUpdated"
              placeholder="app更新状态"
          >
            <a-select-option
                v-for="item of appUpdateTypeList"
                :key="item.value"
                :value="item.value"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="更新描述">
          <a-input
              v-model="params.content"
              class="w-100-w"
              type="textarea"
              :auto-size="{ minRows: 6, maxRows: 10 }"
              placeholder="更新内容描述"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>

import {APP_PLATFORM_TYPE_LIST, APP_UPDATE_TYPE_LIST} from "@/views/cmsPage/appVersionManage/_data"
import {getAppVersionEditor} from "@/views/cmsPage/appVersionManage/_api"

export default {
  data() {
    return {
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      appPlatformTypeList: APP_PLATFORM_TYPE_LIST,
      appUpdateTypeList: APP_UPDATE_TYPE_LIST,
      showPopup: false,
      titleText: '新增版本管理',
      params: {
        deviceType: undefined,
        appVersion: undefined,
        needUpdated: undefined,
        content: undefined
      },
      defaultData: ''
    }
  },
  methods: {
    async show(data) {
      if (data) {
        this.titleText = '编辑版本管理'
        this.params = data
      }
      this.showPopup = true
    },
    setData() {

    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.appVersion) return this.$message.warn('版本号不能为空')
      if (!this.params.deviceType) return this.$message.warn('平台不能为空')
      if (this.params.needUpdated === undefined || this.params.needUpdated == null) return this.$message.warn('更新类型不能为空')
      if (!this.params.content) return this.$message.warn('更新描述不能为空')
      const res = await getAppVersionEditor(this.params)
      if (res.status !== '200') return
      this.$message.info('成功')
      this.$emit('success')
      this.handleCancel()
    },
    /** 取消 */
    handleCancel() {
      this.params = {
        deviceType: undefined,
        appVersion: undefined,
        needUpdated: undefined,
        content: undefined
      }
      this.showPopup = false
    },
  }
}
</script>
<style scoped lang="scss">
</style>