<template>
  <div>
    <a-table
        class="table-box"
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   app平台   -->
      <div slot="itemAppPlatformSlot" slot-scope="row">
        <span
            :class="appPlatFormClassType.get(row.deviceType)"
        >{{ computedAppPlatformTypeMapText(row.deviceType) }}</span>
      </div>
<!--   app更新类型   -->
      <div slot="itemAppUpdateSlot" slot-scope="row">
        <span
            :class="appUpdateClassType[row.needUpdated]"
        >{{ computedAppUpdateTypeMapText(row.needUpdated) }}</span>
      </div>
<!--   app下载路径   -->
      <div slot="itemAppDownloadUrlSlot" slot-scope="row">
        <a-popover title="下载路径">
          <template slot="content">
            <span>{{ row.appUrl }}</span>
          </template>
          <a :href="row.appUrl" target="_blank">下载路径</a>
        </a-popover>
      </div>
<!--   app更新内容   -->
      <div slot="itemAppUpdateContentSlot" slot-scope="row">
        <div v-html="row.content"></div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <a-button
          type="primary"
          class="mr-5"
          @click="handleShowVersionEditor(row)"
        >编辑</a-button>
        <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="handleDeleteVersion(row)"
        >
          <a-button
              type="danger"
          >删除</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <VersionEditorPopup ref="versionEditorPopupEl" @success="handleSuccessEditor"/>
  </div>
</template>
<script>
import {
  APP_PLATFORM_CLASS_TYPE, APP_UPDATE_CLASS_TYPE,
  APP_VERSION_LINE_TABLE_LIST,
  appPlatformTypeMapText, appUpdateTypeMapText
} from "@/views/cmsPage/appVersionManage/_data"
import VersionEditorPopup from "@/views/cmsPage/appVersionManage/_components/VersionEditorPopup"
import {getAppVersionDelete} from "@/views/cmsPage/appVersionManage/_api"

export default {
  props: ['pagination', 'list', 'loading'],
  components: {VersionEditorPopup},
  data() {
    return {
      tableList: APP_VERSION_LINE_TABLE_LIST,
      appPlatFormClassType: APP_PLATFORM_CLASS_TYPE,
      appUpdateClassType: APP_UPDATE_CLASS_TYPE,
    }
  },
  computed: {
    computedAppPlatformTypeMapText() {
      return (value) => {
        return appPlatformTypeMapText(value)
      }
    },
    computedAppUpdateTypeMapText() {
      return (value) => {
        return appUpdateTypeMapText(value)
      }
    }
  },
  methods: {
    /** 删除 */
    async handleDeleteVersion(row) {
      const res = await getAppVersionDelete({id: row.id})
      if (res.status !== '200') return
      this.handleSuccessEditor()
    },
    /** 编辑成功 */
    handleSuccessEditor() {
      this.$emit('success')
    },
    handleShowVersionEditor(row) {
      const tempData = {
        id: row.id,
        deviceType: row.deviceType,
        appVersion: row.appVersion,
        needUpdated: row.needUpdated,
        content: row.content
      }
      this.$refs.versionEditorPopupEl.show(tempData)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    }
  }
}
</script>
<style lang="scss" scoped>
</style>