import instance from "@/axios"

/** app版本管理列表 dq_admin/appVersion/list*/
export const getAppVersionList = async (params) => {
    return instance({
        url: '/dq_admin/appVersion/list',
        method: 'GET',
        params: params
    })
}
/** app版本管理编辑 */
export const getAppVersionEditor = async (params) => {
    return instance({
        url: '/dq_admin/appVersion/addOrEdit',
        method: 'POST',
        data: params
    })
}
/** app版本管理删除 */
export const getAppVersionDelete = async (params) => {
    return instance({
        url: '/dq_admin/appVersion/delById',
        method: 'GET',
        params: params
    })
}