var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        staticClass: "table-box",
        attrs: {
          columns: _vm.tableList,
          pagination: _vm.pagination,
          "data-source": _vm.list,
          rowKey: function(record, index) {
            return index
          }
        },
        on: { change: _vm.changePage },
        scopedSlots: _vm._u([
          {
            key: "itemAppPlatformSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c(
                  "span",
                  { class: _vm.appPlatFormClassType.get(row.deviceType) },
                  [
                    _vm._v(
                      _vm._s(_vm.computedAppPlatformTypeMapText(row.deviceType))
                    )
                  ]
                )
              ])
            }
          },
          {
            key: "itemAppUpdateSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("span", { class: _vm.appUpdateClassType[row.needUpdated] }, [
                  _vm._v(
                    _vm._s(_vm.computedAppUpdateTypeMapText(row.needUpdated))
                  )
                ])
              ])
            }
          },
          {
            key: "itemAppDownloadUrlSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-popover",
                    { attrs: { title: "下载路径" } },
                    [
                      _c("template", { slot: "content" }, [
                        _c("span", [_vm._v(_vm._s(row.appUrl))])
                      ]),
                      _c(
                        "a",
                        { attrs: { href: row.appUrl, target: "_blank" } },
                        [_vm._v("下载路径")]
                      )
                    ],
                    2
                  )
                ],
                1
              )
            }
          },
          {
            key: "itemAppUpdateContentSlot",
            fn: function(row) {
              return _c("div", {}, [
                _c("div", { domProps: { innerHTML: _vm._s(row.content) } })
              ])
            }
          },
          {
            key: "itemActionSlot",
            fn: function(row) {
              return _c(
                "div",
                {},
                [
                  _c(
                    "a-button",
                    {
                      staticClass: "mr-5",
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.handleShowVersionEditor(row)
                        }
                      }
                    },
                    [_vm._v("编辑")]
                  ),
                  _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "确定删除？",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleDeleteVersion(row)
                        }
                      }
                    },
                    [
                      _c("a-button", { attrs: { type: "danger" } }, [
                        _vm._v("删除")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            }
          }
        ])
      }),
      _c("VersionEditorPopup", {
        ref: "versionEditorPopupEl",
        on: { success: _vm.handleSuccessEditor }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }